/* Basic reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  overflow-x: hidden;
}

/* Layout styles */
.layout {
  display: flex;
  min-height: 100vh;
  transition: margin-left 0.3s ease;
}

.layout.sidebar-collapsed .main-content {
  margin-left: 48px;
  width: calc(100% - 48px);
}

/* Sidebar styles */
.sidebar {
  position: fixed;
  width: 250px;
  height: 100vh;
  background: #1a237e;
  color: white;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  transition: all 0.3s ease;
}

.sidebar.collapsed {
  width: 48px;
}

.collapsed-title {
  color: white;
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  transform: rotate(-90deg);
  letter-spacing: 1px;
}

.collapse-button {
  position: absolute;
  right: -10px;
  top: 70px;
  width: 20px;
  height: 20px;
  background: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #1a237e;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 1001;
  font-size: 12px;
}

.collapse-button:hover {
  background: #f5f5f5;
}

.sidebar-header {
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 64px;
}

.sidebar-logo {
  width: 100%;
  height: auto;
}

.sidebar-nav {
  flex: 1;
  padding: 10px 0;
}

.nav-item {
  width: 100%;
  padding: 12px 20px;
  background: none;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.95rem;
  text-align: left;
  transition: background-color 0.2s;
  position: relative;
}

.nav-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-item.active {
  background-color: rgba(255, 255, 255, 0.15);
}

.nav-icon {
  margin-right: 12px;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.nav-chevron {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.submenu {
  background-color: rgba(0, 0, 0, 0.2);
}

.submenu-item {
  padding-left: 52px;
}

.sidebar-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 0;
}

/* Main content styles */
.main-content {
  margin-left: 250px;
  flex: 1;
  padding: 20px;
  background-color: #fff;
  min-height: 100vh;
  position: relative;
  width: calc(100% - 250px);
  overflow-x: auto;
  transition: all 0.3s ease;
}

.sidebar.collapsed + .main-content {
  margin-left: 48px;
  width: calc(100% - 48px);
}

/* Add tooltips for collapsed state */
.sidebar.collapsed .nav-item {
  position: relative;
  padding: 12px 0;
  justify-content: center;
}

.sidebar.collapsed .nav-item:hover::after {
  content: attr(title);
  position: absolute;
  left: 48px;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0,0,0,0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  margin-left: 8px;
  z-index: 1002;
}

/* Center icons in collapsed state */
.sidebar.collapsed .nav-icon {
  margin: 0;
  font-size: 18px;
}

/* Fixed header area in main content */
.content-header {
  position: sticky;
  left: 0;
  background: white;
  margin-bottom: 20px;
  padding-right: 20px;
}

.results-caption {
  font-size: 0.9rem;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  color: #666;
}

/* Table styles */
.table-container {
  width: 100%;
  margin: 0 auto;
  background: white;
}

.table-scroll {
  overflow: auto;
  max-height: calc(100vh - 200px); /* Adjust this value based on your layout */
  position: relative;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}

.sticky-header th {
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: white;
  font-weight: bold;
}

.sortable {
  cursor: pointer;
}

.sortable:hover {
  background-color: #f5f5f5;
}

.child-row {
  background-color: #f8f9fa;
}

.child-row td {
  padding-left: 40px;
}

.child-row td:first-child {
  padding-left: 12px;
}

.expand-column {
  width: 40px;
  text-align: center;
}

.expand-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.expand-button:hover {
  background-color: #f0f0f0;
  border-radius: 4px;
}

/* Add smooth transitions */
.expand-button svg {
  transition: transform 0.2s ease;
}

/* Optional: style the sort indicators */
.sort-indicator {
  margin-left: 4px;
  opacity: 0.7;
}

.inactive-row {
  transition: opacity 0.2s ease;
}

.inactive-row:hover {
  opacity: 0.9 !important;
}

/* Ensure expand button and status text remain visible in inactive rows */
.inactive-row .expand-button,
.inactive-row td:last-child span {
  opacity: 1;
}

/* Inactive row hover effects */
tr:hover {
  opacity: 0.9 !important;
  transition: opacity 0.2s ease;
}

/* Ensure expand button and status remain visible */
tr:hover button,
tr:hover td:last-child span {
  opacity: 1 !important;
}
